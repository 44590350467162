#view-content > p {
  font-size: 1.125rem;
  line-height: 1.7777778;
  margin-bottom: 24px;
}

#view-content > h1 {
  font-size: 2.25rem;
  line-height: 1.3333333;
  margin-bottom: 24px;
}

#view-content > h2 {
  font-size: 1.5rem;
  line-height: 1.3333333;
  margin-bottom: 24px;
}

#view-content > h3 {
  font-size: 1.25rem;
  line-height: 1.3333333;
  margin-bottom: 24px;
}

#view-content > h4 {
  font-size: 1.125rem;
  line-height: 1.3333333;
  margin-bottom: 24px;
}

#view-content > h5 {
  font-size: 1rem;
  line-height: 1.3333333;
  margin-bottom: 24px;
}

#view-content > h6 {
  font-size: 0.875rem;
  line-height: 1.3333333;
  margin-bottom: 24px;
}