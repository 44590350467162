.novel-editor > div > .ProseMirror {
  padding-top: 24px;
}

@media screen and (max-width: 768px) {

  .novel-editor > div > .ProseMirror {
    padding: 16px 24px;
  }
}

.novel-editor-p {
  font-size: 18px;
  margin-bottom: 18px;
}

.novel-editor-hr {
  margin-bottom: 18px;
}

.novel-editor-image {
  margin-bottom: 18px;
  max-width: 430px;
  width: 100%;
}

.novel-editor-view {
  padding: 16px 0;
}

.novel-editor-view-content {
  font-size: 18px;
  padding: 24px;
}